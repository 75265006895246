import React, { useState, useEffect } from 'react'
import Carousel from '../components/Carousel/Carousel'
import Carousel_3 from '../components/Carousel/Carousel_3'
import Carousel_finalizado from '../components/Carousel/Carousel_3_finalizado'
import Carousel_finalizado_1by1 from '../components/Carousel/Carousel_3_finalizado_1by1'

import Navbar from '../components/navbar/Navbar';

import axios from "axios";
import Swal from "sweetalert2";

import autoriza_coljuegos from "../images/gcb.png";
import logo_coljuegos from "../images/footer_terminos_condiciones/Grupo 2624.svg";
import logo_18 from "../images/footer_terminos_condiciones/Grupo 2625.svg";
import "../sass/Inicio.sass"

function Inicio() {

  //const baseURL = "https://api.torneosbetplay.com.co/";
  const baseURL = "https://torneobepro.bet/"; 
  const terminos_y_condiciones = "Jugar sin control causa adicción, el juego es entretenimiento, juega con moderación. Prohibida la venta a menores de edad.";

  const [titulo, setTitulo] = useState("");
  const [titulo2, setTitulo2] = useState("");
  const [titulo3, setTitulo3] = useState("");
  const [titulo4, setTitulo4] = useState("");
  const [dataSelect, setDataSelect] = useState([])
  const [chargeSuccesful, setChargeSuccesful] = useState(false)

  const cargarDatosSelect = async () =>{
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let data = await axios.get(baseURL + "api/get/torneo/all").then(e =>{

      if(e.data.length == 0){
        //setDataSelect([{hola:'flag'}])
        setChargeSuccesful(false)
        Swal.close();
      }else{
        setDataSelect(e.data.torneos)
        setChargeSuccesful(true)
        e.data.torneos.filter(element => element.activo).forEach((element, index) => {
          if(index == 0){
            setTitulo(element.titulo)
          }
          else if(index == 1){
            setTitulo2(element.titulo)
          }
          else if(index == 2){
            setTitulo3(element.titulo)
          }
          else if(index == 3){
            setTitulo4(element.titulo)
          }
        })
        Swal.close();
      }
    });
  }

  const datosSelectJSON = (number_carousel) => {

    let img_array = [];
    let slide_array_src = [];
    let slide_array_titulo = [];
    let slide_array_descripcion = [];
    let slide_array_link = []
    let slide_tam = 0;

    if(number_carousel == 1){

      dataSelect.filter(element => element.activo).forEach((element, index) => {
        slide_array_src.push(element.bannerData)
        slide_array_titulo.push(element.titulo)
        slide_array_descripcion.push(element.descripcion)
      })

      slide_array_link.push("/primero-torneo")
      slide_array_link.push("/segundo-torneo")

      slide_array_src.forEach((element, index) => {
        img_array.push({
          "src" : [
            slide_array_src[index]
            ],
          "alt" : "Image",
          "titulo":[
            slide_array_titulo[index]
            ],
          "decripcion": [
            slide_array_descripcion[index]
            ],
          "link":[
            slide_array_link[index]
          ]
        })
      })

      return {
        "slides":img_array
      }
    }
    else if(number_carousel == 2){

      dataSelect.filter(element => element.activo).forEach((element, index) => {
        slide_array_src.push(element.imageData)
        slide_array_titulo.push(element.titulo)
        slide_array_descripcion.push(element.descripcion)
      })

      slide_array_link.push("/primero-torneo")
      slide_array_link.push("/segundo-torneo")
      slide_array_link.push("/tercero-torneo")
      slide_array_link.push("/cuarto-torneo")

      slide_array_src.forEach((element, index) => {
        img_array.push({
          "src" : [
            slide_array_src[index]
            ],
          "alt" : "Image",
          "titulo":[
            slide_array_titulo[index]
            ],
          "decripcion": [
            slide_array_descripcion[index]
            ],
          "link":[
            slide_array_link[index]
          ]
        })
      })

      return {
        "slides":img_array
    }
    }
    else if(number_carousel == 3){

      slide_tam = 3;
      let index_tam = 0;

      dataSelect.filter(element => !element.activo).forEach((element, index) => {
        if(index_tam == 3){
          img_array.push({
            "src" : slide_array_src,
            "alt" : "Image",
            "titulo":slide_array_titulo,
            "decripcion": slide_array_descripcion
          })
          index_tam = 0;
          slide_array_src = []
          slide_array_titulo = []
          slide_array_descripcion = []
        }
        
        slide_array_src.push(element.imageData)
        slide_array_titulo.push(element.titulo)
        slide_array_descripcion.push(element.descripcion)

        index_tam++;
        
      })
      
      if(index_tam > 0){
        img_array.push({
          "src" : slide_array_src,
          "alt" : "Image",
          "titulo":slide_array_titulo,
          "decripcion": slide_array_descripcion
        })
      }

      return{
        "slides": img_array
      }
    }
    else if(number_carousel == 4){

      dataSelect.filter(element => element.activo).forEach((element, index) => {
        slide_array_src.push(element.imageData)
        slide_array_titulo.push(element.titulo)
        slide_array_descripcion.push(element.descripcion)
      })

      slide_array_link.push("/primero-torneo")
      slide_array_link.push("/segundo-torneo")
      slide_array_link.push("/tercero-torneo")
      slide_array_link.push("/cuarto-torneo")

      slide_array_src.forEach((element, index) => {
        img_array.push({
          "src" : [
            slide_array_src[index]
            ],
          "alt" : "Image",
          "titulo":[
            slide_array_titulo[index]
            ],
          "decripcion": [
            slide_array_descripcion[index]
            ],
          "link":[
            slide_array_link[index]
          ]
        })
      })

      return {
        "slides":img_array
    }
    }
    else if(number_carousel == 5){

      dataSelect.filter(element => !element.activo).forEach((element, index) => {
        slide_array_src.push(element.imageData)
        slide_array_titulo.push(element.titulo)
        slide_array_descripcion.push(element.descripcion)
      })

      slide_array_link.push("/primero-torneo")
      slide_array_link.push("/segundo-torneo")

      slide_array_src.forEach((element, index) => {
        img_array.push({
          "src" : [
            slide_array_src[index]
            ],
          "alt" : "Image",
          "titulo":[
            slide_array_titulo[index]
            ],
          "decripcion": [
            slide_array_descripcion[index]
            ],
          "link":[
            slide_array_link[index]
          ]
        })
      })

      return {
        "slides":img_array
    }
    }
    else{
      return{
        "slides": []
      }
    }
  }

  useEffect(() => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    cargarDatosSelect();
    Swal.close();
  }, []);

  return (
    <>
        <div className='body_inicio'>
          <div id='navbar_inicio'>
            <div className='left'>
              <Navbar className="navbarsito" data = {{"titulos" : [titulo, titulo2, titulo3, titulo4]}} id="navbarsito"/>
            </div>
            <div className='right'>
              <img src={require("../images/minilogo.png")} />
            </div>
          </div>
          <div id='carousel'>
            <div className='carousel_one'>
              <Carousel className='carousel' data = {datosSelectJSON(1).slides}/>
            </div>
            {
              window.innerWidth < 992 && window.innerHeight > 820 ?
              <div className='carousel_two'>
                <h2>Activas</h2>
                <Carousel_3 data = {datosSelectJSON(4).slides}/>
              </div>
              :
              <div className='carousel_two'>
                <h2>Activas</h2>
                <Carousel_3 data = {datosSelectJSON(2).slides}/>
              </div>
            }
            {
              window.innerWidth < 992 && window.innerHeight > 820 ?
              <div className='carousel_three'>
                <h2>Finalizadas</h2>
                <Carousel_finalizado data = {datosSelectJSON(5).slides}/>
              </div>
              :
              <div className='carousel_three'>
                <h2>Finalizadas</h2>
                <Carousel_finalizado_1by1 data = {datosSelectJSON(5).slides}/>
              </div>
            }
            {
              window.innerWidth < 992 && window.innerHeight > 820 ?
              <div className='footer_carousel'>
                <div className='terminos_condiciones_img_div_nuevo'>
                  <div className='img_tight1'>
                    <img
                      src={logo_18}
                    
                      width="38px"
                      height="30px"
                    />
                  </div>
                  
                  <div className='img_tight2'>
                    <img
                      src={logo_coljuegos}

                      width="38px"
                      height="30px"
                    />
                  </div>
                  <div className='img_tight3'>
                    <img
                        src={autoriza_coljuegos}

                        width="85px"
                        height="30px"
                    />
                  </div>
                  <div className='h2_tight'>
                    <h2>
                      Jugar sin control causa adicción,
                    </h2>
                    <h2>
                      el juego es entretenimiento, juega con moderación.
                    </h2>
                    <h2>
                      Prohibida la venta a menores de edad.
                    </h2>
                  </div>

                </div>
                
              </div>
              :
              <footer>
                <div className='terminos_condiciones_img_div'>
                  
                  {/* <img
                    src={logo_18}
                  
                    className='img_tight1'
                    
                    width="38px"
                    height="30px"
                  />
                  <img
                    src={logo_coljuegos}
                  
                    className='img_tight2'

                    width="38px"
                    height="30px"
                  />  */}
                  <img
                      src={autoriza_coljuegos}

                      width="85px"
                      height="30px"
                  />
                </div>
                <h2>
                {terminos_y_condiciones}
                </h2>
              </footer>
            }
            
          </div>
        </div>
          
    </>
  );
}

export default Inicio;