import React from 'react';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'Primer Torneo',
    path: '/primero-torneo',
    icon: <RiIcons.RiNumber1 style={{ color: 'white' }}/>,
    cName: 'nav-text',
  },
  {
    title: 'Segundo Torneo',
    path: '/segundo-torneo',
    icon: <RiIcons.RiNumber2 style={{ color: 'white' }}/>,
    cName: 'nav-text',
  },
  {
    title: 'Tercero Torneo',
    path: '/tercero-torneo',
    icon: <RiIcons.RiNumber3 style={{ color: 'white' }}/>,
    cName: 'nav-text',
  },
  {
    title: 'Cuarto Torneo',
    path: '/cuarto-torneo',
    icon: <RiIcons.RiNumber4 style={{ color: 'white' }}/>,
    cName: 'nav-text',
  },
];