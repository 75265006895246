import React, { useState } from 'react';
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai'
import "../Carousel/Carousel.sass"

const Carousel = ( {data} ) => {

    const [indexCarousel, setIndexCarousel] = useState(0)

    const nextSlide = () => {
        setIndexCarousel(indexCarousel === data.length - 1 ? 0 : indexCarousel + 1);
    }

    const previousSlide = () => {
        setIndexCarousel(indexCarousel === 0 ? data.length - 1 : indexCarousel - 1);
    }

    return (
        <>
            
            <div className='carousel_div_large'>
                
                {data.map((item, index) => { return(
                    <>
                        <a href={item.link}>
                            <img 
                                src={item.src[0]} 
                                alt={item.alt} 
                                key={index} 
                                className={indexCarousel === index ? 'carousel_img' : 'carousel_img carousel_img_hidden' }
                            />
                        </a>  
                    </>
                    )})
                }
            
                <span className='indicators'>
                    {data.map((_ , index) => {
                        return(
                            <button key={index} onClick={() => setIndexCarousel(index)} className={indexCarousel === index ? 'indicator_button' : 'indicator_button indicator_button_inactive'}>

                            </button>
                        )})
                    }
                </span>
            </div>
            
        </>
    )
}

export default Carousel