import React from 'react';
import Excel from '../components/excel/Excel';
import Navbar from '../components/navbar/Navbar';

function CargaExcel() {
  return (
    <>
      <div style={{marginTop:'2%'}}></div>
      <Navbar data = {{"titulos" : []}}/>
      <div className='primer-torneo' style={{marginTop:'2%', marginBottom:'3%'}}>
        <div style={{marginLeft:'39%'}}>
          <h1 style={{color:"#fff"}}>Carga de excel</h1>
        </div>
      </div>
      <div className='tabla-excel'>
        <Excel/>
      </div>
    </>
  );
}

export default CargaExcel;